export const i18nAssets = {
  "en-US": {
    PORTAL_LABEL: {
      TRAINING_GUIDE: "Training Guide",
      OK: "OK",
      LEGAL: "Legal",
      PRIVACY: "Privacy",
      ACCESSIBILITY: "Accessibility",
      UPDATE_PASSWORD: "Update Password",
      SELECT_REGION_LANGUAGE: "Select Region / Language",
      SELECT_LANGUAGE: "Select Language",
      LOGIN_BTN: "Login",
      LOGIN_DISCLAIMER: "Login Disclaimer",
      REG_ENV: "Regulatory Environment",
      USERNAME: "Username",
      PWD: "Password",
      EMAIL_ID: "Email ID",
      CA_UI: "Customer Credit Application",
      CA_UI_RETAIL: "Retail Credit Application",
      DMC_UI: "Deal Management Center",
      SALESREP_UI: "Emulate Dealer",
      REPORTS_UI: "Reports",
      ADMIN_UI: "Administration",
      DEALER_ADMINISTRATION: 'Dealer Administration',
      MYPROFILE: "My Profile",
      NEW_DEALS: "New Deals",
      COMM: "Communication",
      DOC_LIB: "Document Library",
      PAYMENT_ESTIMATES: "Payment Estimator",
      WELCOME_PACKAGE: "Welcome Package",
      LOG_OFF: "Log Off",
      LANGUAGE: "Language",
      FMV: "Fair Market Value (FMV) Lease",
      LOANS: "Loans",
      TRAC_LEASE: "Trac Lease",
      UNIQUE_STRUCTURES: "Unique Structures",
      QUASI_LEASE: "Quasi Lease",
      LEASING_BENEFITS: "Benefits of Leasing",
      LEASE_LOAN_DIFF: "Difference Between Lease and Loan",
      LEASING_GLOSSARY: "Leasing Glossary",
      FINANCING_GLOSSARY: "Financing Glossary",
      DOC: "Document",
      LIBRARY: "Library",
      TRAINING: "Training",
      TRAINING_LIB: "Training Library",
      PRODS: "Products",
      SERVICES: "Services",
      ACCEPT: "Accept",
      DECLINE: "Decline",
      HOME: "Home",
      HOME_CA: "Home",
      CONFIRM_PASSWORD: "Confirm Password",
      TERMS_CONDITION: "Terms and Conditions",
      SECURITY: "Security",
      SECURITY_CA: "Security",
      SITE_SECURITY: "Site Security",
      FEEDBACK: "Feedback",
      CUST_COMPLAINT: "Customer Complaint",
      USER_NAME: "User Name",
      CHALLENGE_QUESTION: "*Challenge Question",
      CHALLANGE_RESPONCE: "Challange Responce",
      NOT_FOUND_PAGE: "Not-found page!",
      USER_NAME_FORM: "User Name Form",
      COPYRIGHT_INFO: "© {{year}} BMO Bank N.A. All rights reserved.",
      BRAND_URL: "Navistar.com",
      UNAUTHORIZED_ERROR_MESSAGE_1:
        "You are not Authorized to view this page. Please contact your Sales Representative for immediate assistance.",
      UNAUTHORIZED_ERROR_MESSAGE_2:
        "REMINDER: Your 'username' and 'password' are case sensitive, if you require assistance, please contact your sales representative.",
      UNAUTHORIZED_ERROR_MESSAGE_3: "We are sorry",
      NOTIFICATION_1:
        "Your account has been disabled or deleted. You are being redirected to your login page.",
      NOTIFICATION_2:
        "Your permissions have changed. You are being redirected to the home page.",
      NOTIFICATION_3:
        "Your password has been reset successfully. You are being redirected to the login page.",
      PASSWORD_MISMATCH: "Password mismatch.",
      PASS_RESET_FAIL:
        "Password reset cannot be successful due to invalid credentials",
      PASS_RESET_SUCCESS:
        "Your Password has been reset successfully. Please close your browser and login into the site with your new password.",
      UNAUTHORIZED_ERROR_MESSAGE:
        "This page will be automatically redirect in {{logoutTime}} seconds.",
      FIRST_USER_MSG_1: "Please read the above document and select checkbox",
      FIRST_USER_MSG_2:
        "By clicking on the 'Accept' button, you acknowledge that you have read and understand the documents.",
      REQUIRED_INFO: "Please provide the following information",
      SUPPORT_MSG:
        "If you are having trouble please contact your site administrator or call {{phoneNo}} for assistance.",
      SUPPORT_MSG_1:
        "If this is not one of your security questions, your User Name is not recognized or you may not have been registered.",
      TRANSPORTATION: {
        IDEALEASE_CA_UI: "Dealer Lease Finance",
      },
      TRANSPORTATION_CA: {
        IDEALEASE_CA_UI: "Dealer Lease Finance",
      },
      NFS: {
        IDEALEASE_CA_UI: "Idealease Credit Application",
      },
      NFS_CA: {
        IDEALEASE_CA_UI: "Idealease Credit Application",
      },
      LOGIN: {
        LOGIN_TITLE: "Login",
        NEW_USER: "New User?",
        REGISTER: "Register",
        FORGOT_PASSWORD: "Forgot Password?",
        HELP: "Help",
        VERIFY_USER: "Verify User",
        CONTINUE: "Continue",
        RETURN_TO_SIGN_IN: "Return to Sign-in",

        VERIFY_TITLE: "Verify Identity",
        VERIFY_DESCRIPTION:
          "For your security, we need to verify your device. Please select an option to receive a verification code.",
        RESET_PASSWORD_DESCRIPTION:
          "For your security, we need to verify your reset request. Please select an option to receive your verification code.",
        NOTICE: "Notice",
        VERIFY_INFO1:
          "For your protection, we need to take some additional steps to help verify who you are.",
        VERIFY_INFO2:
          "When accessing the site from a new device, we'll send a numeric code using the delivery method you've selected.",
        VERIFY_INFO3:
          "Once you've received the code please enter it on the next screen so you can access your account",
        EMAIL: "Email",
        TEXT: "Text",
        CALL: "Call",
        SEND_CODE: "Send Code",
        CODE_TITLE: "Enter Code",
        CODE_DESC1: "Your code was sent to ",
        CODE_DESC2: "Enter your code as soon as you receive it.",
        ONE_TIME_CODE: "One-Time Code",
        REMEMBER_DEVICE: "Remember this device",
        REMEMBER_WARNING:
          "For your security, we do not recommend using this feature on a shared or public device.",
        CODE_INFO1:
          "You may not receive your code immediately. Please allow a few minutes for delivery.",
        CODE_INFO2: "If you did not receive your code please check:",
        CODE_INFO3:
          "Your phone is on and has coverage based on your current location, and is able to receive messages.",
        CODE_INFO4: "You've verified that your contact information is correct.",
        NO_CODE: "Didn`t get the code?",
        RESEND_CODE: "Resend Code",
        UPDATE_PWD_TITLE: "Update Password",
        UPDATE_PWD_DESC: "Please create a new password.",
        NEW_PWD: "New Password",
        COMFIRM_PWD: "Confirm New Password",
        PWD_NOTE:
          "Password should be 8 to 20 characters in length and at least one upper case letter, one lower case letter, one number, and one special character : !@#$%^&*()-_",
        FORGOT_PWD_DESC:
          "Thank you for confirming your account, please enter your new password in order to update your login.",
      },
      REGISTRATION: {
        HEADER: "Registration",
        COMPANY_RADIO:
          "I would like to register my company to have access to this site",
        USER_RADIO:
          "My company already has access to this site: however, I do not. I would like access to this site.",
        CANCEL: "Cancel",
        SUBMIT: "Submit",
        AFFILIATIONS: "Manufacturer Affiliations",
        AUTH_MANUFACTURER: "Authorized Manufacturer",
        EQUIPMENT_DESC: "Equipment Description",
        RESET: "Reset",
        REGISTER: "Register",
        COMPANY_REG_FORM: "Company Registration Form",
        USER_REG_FORM: "User Registration Form",
        COMPANY_FORM_INFO:
          "Please complete the following form to request access to the Leasingsource web site and/or to recieve authorization to use our financial services.",
        USER_FORM_INFO_1:
          "When your company was registered to access this site, an individual at your company was given administration rights to grant additional users permission to access this site. If you know who your designated site Administrator is, please contact them for access or contact your Account Representative.",
        USER_FORM_INFO_2:
          "If more convenient, please complete the form below and we will work with your Account Representative to grant you access to the site.",
        CONTACT_US: "Contact Us",
        INDICATES: "Indicates",
        REQUIRED: "Required",
        FIELD: "field",
        COMPANY_INFO: "Company Information",
        COMPANY_NAME: "Company Name",
        DBA_TRADE_NAME: "DBA or Trade Name",
        HQ: "Headquarters",
        BRANCH: "Branch",
        STREET_ADDRESS: "Street Address",
        PO_BOX: "(Cannot use P.O. Boxes)",
        CITY: "City",
        STATE: "State",
        PLEASE_SELECT: "Please Select",
        ZIP: "Zip",
        PHONE: "Phone No.",
        "1_800s": "(Cannot use 1-800#s)",
        FAX: "Fax no.",
        BUSINES_TYPE: "Type of Business",
        PROPRIETORSHIP: "Proprietorship",
        PARTNERSHIP: "Partnership",
        CORPORATION: "Corporation",
        LESS_THAN_TWO: "Have you been in business less than 2 years?",
        YES: "Yes",
        NO: "No",
        COMPANY_CONTACT_INFO: "Principal Contact Information",
        USER_CONTACT_INFO: "Contact Information",
        FIRST_NAME: "First Name",
        LAST_NAME: "Last Name",
        TITLE: "Title",
        EMAIL: "Email",
        CONFIRM_EMAIL: "Confirm Email",
        SUCCESS_1:
          "Thank you for your request. Your Account Representative or Dealer Support will reach out to you with your access information.",
        SUCCESS_2:
          "If you have any questions regarding your access to the site, please contact your Account Representative at {{phoneNo}} option 1.",
      },
      USER_PASSWORD_ERROR:
        "You have entered an invalid username and password. Please try again.",
      USERNAME_EMAIL_ERROR:
        "You have entered an invalid username or email. Please try again.",
      LOGIN_ATTEMPTS_ERROR:
        "You have exceeded the limit of login attempts. You cannot access your account at this time.",
      FAILED_LOGIN_ERROR: "Failed login attempt. Try again.",
      GENERIC_LOGIN_ERROR:
        "An unknown issue has occured. Please close your browser and contact your system administrator for further assistance.",
    },
    PORTAL_ERROR: {
      NOT_FOUND_ERR: "Not-found page!",
      ERR_MESG_INVALID_REQUIRED: "Required field cannot be blank.",
      ERR_MESG_INVALID_PASSWORD:
        "Insufficient Password. Password length should be 6 to 20 characters and at least one upper case letters, lower case letters, numbers and special characters:!@#$%^&*()-_",
      ERR_MESG_INVALID_PASSWORD_FORGOT:
        "Insufficient Password. Password length should be 6 to 20 characters and at least one upper case letters, lower case letters, numbers and special characters:!@#$%^&*()-_",
      ERR_MESG_INVALID_NEW_PASSWORD:
        "Insufficient Password. Please follow the requirements below.",
      ERR_MESG_INVALID_CONFIRM_NEW_PASSWORD:
        "New password and confirm password do not match",
      ERR_MESG_INVALID_ZIP_CODE:
        "This field must be a 5-digit U.S. ZIP Code (like 94043).",
      ERR_MESG_INVALID_NUMERIC: "Please enter numeric value.",
      ERR_MESG_INVALID_PHONE:
        "Please enter a valid phone number( e.g (203) 123-1234)",
      ERR_MESG_INVALID_EMAIL: "Please enter valid email address.",
      ERR_MESG_EMAIL_MISMATCH: "Email mismatch!",
      ERR_MESG_INVALID_FAX:
        "Please enter a valid fax number( e.g (203) 123-1234)",
      ERR_MESG_INVALID_PERSONAL_NAME: "Please enter a valid name.",
      ERR_MESG_INVALID_TITLE: "Please enter a valid title.",
      ERR_MESG_INVALID_USERNAME: "Please enter a valid user name.",
      ERR_MESG_DOC_MANDATORY:
        "Please open and read the document before choosing 'Accept'",
      ERR_MESG_SERVER_TIMEOUT:
        "There was a problem contacting the server. Please try again later.",
      ERR_MESG_REQUIRED_OPTION: "Please select an option.",
      ERR_MESG_OTP_OPTION: "Failed attempt. Try again.",
      ERR_MESG_REQUIRED_CODE: "Please enter valid code.",
      ERR_MESG_INVALID_OTP_CODE: "Incorrect code entry. Try again.",
      ERR_MESG_INVALID_UPDATE_PASSWORD:
        "Unable to update password. The value provided for the new password does not meet length, complexity, or history requirements of the site.",
      ERR_MESG_INVALID_OLD_PASSWORD:
        "Invalid password. Please enter a new password that is not one you have previously used.",
    },
  },
  "en-CA": {
    PORTAL_LABEL: {
      TRAINING_GUIDE: "Training Guide",
      OK: "OK",
      LEGAL: "Legal",
      PRIVACY: "Privacy",
      ACCESSIBILITY: "Accessibility",
      SELECT_REGION_LANGUAGE: "Select Region / Language",
      UPDATE_PASSWORD: "Update Password",
      SELECT_LANGUAGE: "Select Language",
      SELECT_LANGUAGE_LINK: "Choisissez votre langue – Français",
      LOGIN_BTN: "Sign In",
      LOGIN_DISCLAIMER: "Login Disclaimer",
      REG_ENV: "Regulatory Environment",
      USERNAME: "Username",
      PWD: "Password",
      EMAIL_ID: "Email ID",
      CA_UI: "Customer Credit Application",
      CA_UI_RETAIL: "Retail Credit Application",
      DMC_UI: "Deal Management Center",
      SALESREP_UI: "Emulate Dealer",
      REPORTS_UI: "Reports",
      ADMIN_UI: "Administration",
      DEALER_ADMINISTRATION: 'Dealer Administration',
      MYPROFILE: "My Profile",
      NEW_DEALS: "New Deals",
      COMM: "Communication",
      DOC_LIB: "Document Library",
      PAYMENT_ESTIMATES: "Payment Estimator",
      WELCOME_PACKAGE: "Welcome Package",
      LOG_OFF: "Log Off",
      LANGUAGE: "Language",
      FMV: "Fair Market Value (FMV) Lease",
      LOANS: "Loans",
      TRAC_LEASE: "TRAC Lease",
      UNIQUE_STRUCTURES: "Unique Structures",
      QUASI_LEASE: "Quasi Lease",
      LEASING_BENEFITS: "Benefits of Leasing",
      LEASE_LOAN_DIFF: "Difference Between Lease and Loan",
      LEASING_GLOSSARY: "Leasing Glossary",
      FINANCING_GLOSSARY: "Financing Glossary",
      DOC: "Document",
      LIBRARY: "Library",
      TRAINING: "Training",
      TRAINING_LIB: "Training Library",
      PRODS: "Products",
      SERVICES: "Services",
      ACCEPT: "Accept",
      DECLINE: "Decline",
      HOME: "Home",
      HOME_CA: "Home",
      CONFIRM_PASSWORD: "Confirm Password",
      TERMS_CONDITION: "Terms and Conditions",
      SECURITY: "Security",
      SECURITY_CA: "Security",
      FEEDBACK: "Feedback",
      CUST_COMPLAINT: "Customer Complaint",
      USER_NAME: "User Name",
      CHALLENGE_QUESTION: "*Challenge Question",
      CHALLANGE_RESPONCE: "Challange Responce",
      NOT_FOUND_PAGE: "Not-found page!",
      USER_NAME_FORM: "User Name Form",
      COPYRIGHT_INFO: "© {{year}} Bank of Montreal. All rights reserved.",
      BRAND_URL: "Navistar.com",
      UNAUTHORIZED_ERROR_MESSAGE_1:
        "You are not Authorized to view this page. Please contact your Sales Representative for immediate assistance.",
      UNAUTHORIZED_ERROR_MESSAGE_2:
        "REMINDER: Your 'username' and 'password' are case sensitive, if you require assistance, please contact your sales representative.",
      UNAUTHORIZED_ERROR_MESSAGE_3: "We are sorry",
      NOTIFICATION_1:
        "Your account has been disabled or deleted. You are being redirected to your login page.",
      NOTIFICATION_2:
        "Your permissions have changed. You are being redirected to the home page.",
      NOTIFICATION_3:
        "Your password has been reset successfully. You are being redirected to the login page.",
      PASSWORD_MISMATCH: "Password mismatch.",
      PASS_RESET_FAIL:
        "Password reset cannot be successful due to invalid credentials",
      PASS_RESET_SUCCESS:
        "Your Password has been reset successfully. Please close your browser and login into the site with your new password.",
      UNAUTHORIZED_ERROR_MESSAGE:
        "This page will be automatically redirect in {{logoutTime}} seconds.",
      FIRST_USER_MSG_1: "Please read the above document and select checkbox",
      FIRST_USER_MSG_2:
        "By clicking on the 'Accept' button, you acknowledge that you have read and understand the documents.",
      REQUIRED_INFO: "Please provide the following information",
      SUPPORT_MSG:
        "If you are having trouble please contact your site administrator or call {{phoneNo}} for assistance.",
      SUPPORT_MSG_1:
        "If this is not one of your security questions, your User Name is not recognized or you may not have been registered.",
      TRANSPORTATION: {
        IDEALEASE_CA_UI: "Dealer Lease Finance",
      },
      TRANSPORTATION_CA: {
        IDEALEASE_CA_UI: "Dealer Lease Finance",
      },
      NFS: {
        IDEALEASE_CA_UI: "Idealease Credit Application",
      },
      NFS_CA: {
        IDEALEASE_CA_UI: "Idealease Credit Application",
      },
      LOGIN: {
        LOGIN_TITLE: "BusinessPro Login",
        NEW_USER: "New User?",
        REGISTER: "Register",
        FORGOT_PASSWORD: "Forgot Password?",
        HELP: "Help",
        VERIFY_USER: "Verify User",
        CONTINUE: "Continue",
        RETURN_TO_SIGN_IN: "Return to Sign-in",

        VERIFY_TITLE: "Verify Identity",
        VERIFY_DESCRIPTION:
          "For your security, we need to verify your device. Please select an option to receive a verification code.",
        RESET_PASSWORD_DESCRIPTION:
          "For your security, we need to verify your reset request. Please select an option to receive your verification code.",
        NOTICE: "Notice",
        VERIFY_INFO1:
          "For your protection, we need to take some additional steps to help verify who you are.",
        VERIFY_INFO2:
          "When accessing the site from a new device, we'll send a numeric code using the delivery method you've selected.",
        VERIFY_INFO3:
          "Once you've received the code please enter it on the next screen so you can access your account",
        EMAIL: "Email",
        TEXT: "Text",
        CALL: "Call",
        SEND_CODE: "Send Code",
        CODE_TITLE: "Enter Code",
        CODE_DESC1: "Your code was sent to ",
        CODE_DESC2: "Enter your code as soon as you receive it.",
        ONE_TIME_CODE: "One-Time Code",
        REMEMBER_DEVICE: "Remember this device",
        REMEMBER_WARNING:
          "For your security, we do not recommend using this feature on a shared or public device.",
        CODE_INFO1:
          "You may not receive your code immediately. Please allow a few minutes for delivery.",
        CODE_INFO2: "If you did not receive your code please check:",
        CODE_INFO3:
          "Your phone is on and has coverage based on your current location, and is able to receive messages.",
        CODE_INFO4: "You've verified that your contact information is correct.",
        NO_CODE: "Didn`t get the code?",
        RESEND_CODE: "Resend Code",
        UPDATE_PWD_TITLE: "Update Password",
        UPDATE_PWD_DESC:
          "Please create your own unique password to sign into the application.",
        NEW_PWD: "New Password",
        COMFIRM_PWD: "Confirm New Password",
        PWD_NOTE:
          "Password should be 8 to 20 characters in length and at least one upper case letter, one lower case letter, one number, and one special character : !@#$%^&*()-_",
        FORGOT_PWD_DESC:
          "Thank you for confirming your account, please enter your new password in order to update your login.",
      },
      REGISTRATION: {
        HEADER: "Registration",
        COMPANY_RADIO:
          "I would like to register my company to have access to this site",
        USER_RADIO:
          "My company already has access to this site: however, I do not. I would like access to this site.",
        CANCEL: "Cancel",
        SUBMIT: "Submit",
        AFFILIATIONS: "Manufacturer Affiliations",
        AUTH_MANUFACTURER: "Authorized Manufacturer",
        EQUIPMENT_DESC: "Equipment Description",
        RESET: "Reset",
        REGISTER: "Register",
        COMPANY_REG_FORM: "Company Registration Form",
        USER_REG_FORM: "User Registration Form",
        COMPANY_FORM_INFO:
          "Please complete the following form to request access to the Leasingsource web site and/or to recieve authorization to use our financial services.",
        USER_FORM_INFO_1:
          "When your company was registered to access this site, an individual at your company was given administration rights to grant additional users permission to access this site. If you know who your designated site Administrator is, please contact them for access or contact your Account Representative.",
        USER_FORM_INFO_2:
          "If more convenient, please complete the form below and we will work with your Account Representative to grant you access to the site.",
        CONTACT_US: "Contact Us",
        INDICATES: "Indicates",
        REQUIRED: "Required",
        FIELD: "field",
        COMPANY_INFO: "Company Information",
        COMPANY_NAME: "Company Name",
        DBA_TRADE_NAME: "DBA or Trade Name",
        HQ: "Headquarters",
        BRANCH: "Branch",
        STREET_ADDRESS: "Street Address",
        PO_BOX: "(Cannot use P.O. Boxes)",
        CITY: "City",
        STATE: "State",
        PLEASE_SELECT: "Please Select",
        ZIP: "Zip",
        PHONE: "Phone No.",
        "1_800s": "(Cannot use 1-800#s)",
        FAX: "Fax no.",
        BUSINES_TYPE: "Type of Business",
        PROPRIETORSHIP: "Proprietorship",
        PARTNERSHIP: "Partnership",
        CORPORATION: "Corporation",
        LESS_THAN_TWO: "Have you been in business less than 2 years?",
        YES: "Yes",
        NO: "No",
        COMPANY_CONTACT_INFO: "Principal Contact Information",
        USER_CONTACT_INFO: "Contact Information",
        FIRST_NAME: "First Name",
        LAST_NAME: "Last Name",
        TITLE: "Title",
        EMAIL: "Email",
        CONFIRM_EMAIL: "Confirm Email",
        SUCCESS_1:
          "Thank you for your request. Your Account Representative or Dealer Support will reach out to you with your access information.",
        SUCCESS_2:
          "If you have any questions regarding your access to the site, please contact your Account Representative at {{phoneNo}} option 1.",
      },
      USER_PASSWORD_ERROR:
        "You have entered an invalid username and password. Please try again.",
      USERNAME_EMAIL_ERROR:
        "You have entered an invalid username or email. Please try again.",
      LOGIN_ATTEMPTS_ERROR:
        "You have exceeded the limit of login attempts. You cannot access your account at this time.",
      FAILED_LOGIN_ERROR: "Failed login attempt. Try again.",
      GENERIC_LOGIN_ERROR:
        "An unknown issue has occured. Please close your browser and contact your system administrator for further assistance.",
    },
    PORTAL_ERROR: {
      NOT_FOUND_ERR: "Not-found page!",
      ERR_MESG_INVALID_REQUIRED: "Required field cannot be blank.",
      ERR_MESG_INVALID_PASSWORD:
        "Insufficient Password. Password length should be 6 to 20 characters and at least one upper case letters, lower case letters, numbers and special characters:!@#$%^&*()-_",
      ERR_MESG_INVALID_PASSWORD_FORGOT:
        "Insufficient Password. Password length should be 6 to 20 characters and at least one upper case letters, lower case letters, numbers and special characters:!@#$%^&*()-_",
      ERR_MESG_INVALID_NEW_PASSWORD:
        "Insufficient Password. Please follow the requirements below.",
      ERR_MESG_INVALID_CONFIRM_NEW_PASSWORD:
        "New password and confirm password do not match",
      ERR_MESG_INVALID_ZIP_CODE:
        "This field must be a 5-digit U.S. ZIP Code (like 94043).",
      ERR_MESG_INVALID_NUMERIC: "Please enter numeric value.",
      ERR_MESG_INVALID_PHONE:
        "Please enter a valid phone number( e.g (203) 123-1234)",
      ERR_MESG_INVALID_EMAIL: "Please enter valid email address.",
      ERR_MESG_EMAIL_MISMATCH: "Email mismatch!",
      ERR_MESG_INVALID_FAX:
        "Please enter a valid fax number( e.g (203) 123-1234)",
      ERR_MESG_INVALID_PERSONAL_NAME: "Please enter a valid name.",
      ERR_MESG_INVALID_TITLE: "Please enter a valid title.",
      ERR_MESG_INVALID_USERNAME: "Please enter a valid user name.",
      ERR_MESG_DOC_MANDATORY:
        "Please open and read the document before choosing 'Accept'",
      ERR_MESG_SERVER_TIMEOUT:
        "There was a problem contacting the server. Please try again later.",
      ERR_MESG_REQUIRED_OPTION: "Please select an option.",
      ERR_MESG_OTP_OPTION: "Failed attempt. Try again.",
      ERR_MESG_REQUIRED_CODE: "Please enter valid code.",
      ERR_MESG_INVALID_OTP_CODE: "Incorrect code entry. Try again.",
      ERR_MESG_INVALID_UPDATE_PASSWORD:
        "Unable to update password. The value provided for the new password does not meet length, complexity, or history requirements of the site.",
      ERR_MESG_INVALID_OLD_PASSWORD:
        "Invalid password. Please enter a new password that is not one you have previously used.",
    },
  },
  "fr-CA": {
    PORTAL_LABEL: {
      TRAINING_GUIDE: "Guide de formation",
      OK: "OK",
      PRIVACY: "Confidentialité",
      LEGAL: "Mentions légales",
      ACCESSIBILITY: "Accessibilité",
      UPDATE_PASSWORD: "Mettre à jour le mot de passe",
      SELECT_REGION_LANGUAGE: "Sélectionnez votre région ou langue",
      SELECT_LANGUAGE: "Sélectionnez votre langue",
      SELECT_LANGUAGE_LINK: "Change language – English",
      LOGIN_BTN: "Ouverture de session",
      LOGIN_DISCLAIMER: "Avertissement lié à l’ouverture de session",
      REG_ENV: "Environnement réglementaire",
      USERNAME: "Nom d’utilisateur",
      PWD: "mot de passe",
      QUASI_LEASE: "Crédit-bail - QUASI",
      EMAIL_ID: "Adresse courriel",
      CA_UI: "Demande de crédit client",
      CA_UI_RETAIL: "Demande de Crédit’",
      DMC_UI: "Centre de gestion des transactions",
      SALESREP_UI: "Émuler un concessionnaire",
      REPORTS_UI: "Rapports",
      ADMIN_UI: "Administration",
      DEALER_ADMINISTRATION: 'Administration du Concessionnaire',
      MYPROFILE: "Mon profil",
      NEW_DEALS: "Nouvelles transactions",
      COMM: "Communication",
      DOC_LIB: "Bibliothèque de documents",
      PAYMENT_ESTIMATES: "Estimateur de paiement",
      WELCOME_PACKAGE: "Bienvenue du concessionnaire",
      LOG_OFF: "Fermeture de session",
      LANGUAGE: "Langue",
      FMV: "Juste valeur marchande (crédit-bail à la JVM)",
      LOANS: "Prêts",
      TRAC_LEASE: "Crédit-bail - TRAC",
      UNIQUE_STRUCTURES: "Structures uniques",
      LEASING_BENEFITS: "Avantages de la location",
      LEASE_LOAN_DIFF: "Différence entre la location et le prêt",
      LEASING_GLOSSARY: "Glossaire de location",
      FINANCING_GLOSSARY: "Glossaire financier",
      DOC: "Bibliothèque",
      LIBRARY: "de Documents",
      TRAINING: "Formation",
      TRAINING_LIB: "Bibliothèque de formation",
      PRODS: "Produits",
      SERVICES: "Services",
      ACCEPT: "Accepter",
      DECLINE: "Refusé",
      HOME: "Accueil",
      HOME_CA: "Page d’accueil",
      CONFIRM_PASSWORD: "Confirmez le mot de passe",
      TERMS_CONDITION: "Modalités",
      SECURITY: "Garanties",
      SECURITY_CA: "Sécurité",
      FEEDBACK: "Rétroaction",
      CUST_COMPLAINT: "Plainte d'un client",
      USER_NAME: "Nom d’utilisateur",
      CHALLENGE_QUESTION: "*Question de défi",
      CHALLANGE_RESPONCE: "Réponse au défi",
      NOT_FOUND_PAGE: "Page introuvable!",
      USER_NAME_FORM: "Formulaire de nom d'utilisateur",
      COPYRIGHT_INFO: "© {{year}}Banque de Montréal. Tous droits réservés.",
      BRAND_URL: "Navistar.com",
      UNAUTHORIZED_ERROR_MESSAGE_1:
        "Vous n’êtes pas autorisé à consulter cette page. Veuillez communiquer avec votre représentant commercial pour obtenir une aide immédiate",
      UNAUTHORIZED_ERROR_MESSAGE_2:
        "RAPPEL:votre nom d’utilisateur et mot de passe sont sensibles à la casse. Si vous avez besoin d’aide, veuillez communiquer avec votre représentant commercial.",
      UNAUTHORIZED_ERROR_MESSAGE_3: "Nous sommes désolés",
      NOTIFICATION_1:
        "Votre compte a été désactivé ou supprimé. Vous allez être redirigé vers la page d’ouverture de session.",
      NOTIFICATION_2:
        "Vos autorisations ont été modifiées. Vous allez être redirigé vers la page d’accueil.",
      NOTIFICATION_3:
        "Votre mot de passe a été réinitialisé avec succès. Vous allez être redirigé vers la page d’ouverture de session.",
      PASSWORD_MISMATCH: "Les mots de passe ne correspondent pas.",
      PASS_RESET_FAIL:
        "Impossible de réinitialiser le mot de passe en raison d'identifiants invalides.",
      PASS_RESET_SUCCESS:
        "Votre mot de passe a été réinitialisé avec succès. Veuillez fermer votre navigateur et ouvrir une session sur le site avec votre nouveau mot de passe.",
      UNAUTHORIZED_ERROR_MESSAGE:
        "Cette page sera automatiquement redirigée dans {{logoutTime}} secondes.",
      FIRST_USER_MSG_1: "Veuillez lire le document ci-dessus et cochez la case",
      FIRST_USER_MSG_2:
        "En cliquant sur le bouton «Accepter», vous reconnaissez avoir lu et compris les documents.",
      REQUIRED_INFO: "Veuillez fournir les renseignements suivants",
      SUPPORT_MSG:
        "Si vous éprouvez des difficultés, veuillez contacter votre administrateur de site ou appeler au {{phoneNo}} pour obtenir de l’aide.",
      SUPPORT_MSG_1:
        "S’il ne s’agit pas d’une de vos questions de sécurité, votre nom d’utilisateur n’est pas reconnu ou vous n’êtes peut-être pas inscrit.",
      TRANSPORTATION: {
        IDEALEASE_CA_UI: "Crédit-bail par le concessionnaire",
      },
      TRANSPORTATION_CA: {
        IDEALEASE_CA_UI: "Crédit-bail par le concessionnaire",
      },
      NFS: {
        IDEALEASE_CA_UI: "Demande de crédit Idealease",
      },
      NFS_CA: {
        IDEALEASE_CA_UI: "Demande de crédit Idealease",
      },
      LOGIN: {
        LOGIN_TITLE: "Ouverture de session BusinessPro",
        NEW_USER: "Nouvel utilisateur?",
        REGISTER: "Inscrire",
        FORGOT_PASSWORD: "Mot de passe oublié?",
        HELP: "Aider",
        VERIFY_USER: "Vérifier l'utilisateur",
        CONTINUE: "Continuer",
        RETURN_TO_SIGN_IN: "Revenir à la connexion",

        VERIFY_TITLE: "Vérifier l'identité",
        VERIFY_DESCRIPTION:
          "Pour votre sécurité, nous devons vérifier votre appareil. Veuillez sélectionner une option pour recevoir un code de vérification.",
        RESET_PASSWORD_DESCRIPTION:
          "Pour votre sécurité, nous devons vérifier votre appareil. Veuillez sélectionner une option pour recevoir un code de vérification.",
        NOTICE: "Remarquer",
        VERIFY_INFO1:
          "For your protection, we need to take some additional steps to help verify who you are.", // translation missing
        VERIFY_INFO2:
          "When accessing the site om a new device, we'll send a numeric code using the delivery method you've selected.", // translation missing
        VERIFY_INFO3:
          "Après avoir reçu votre code, veuillez l’entrer à l’écran suivant pour accéder à votre compte",
        EMAIL: "Eourriel",
        TEXT: "Texte",
        CALL: "Appel",
        SEND_CODE: "Envoyer le code",
        CODE_TITLE: "Entrez le code",
        CODE_DESC1: "Votre code a été envoyé à ",
        CODE_DESC2: "Entrez votre code dès que vous le recevez",
        ONE_TIME_CODE: "Code à usage unique",
        REMEMBER_DEVICE: "Rappelez-vous cet appareil",
        REMEMBER_WARNING:
          "Pour votre sécurité, nous vous déconseillons d’utiliser cette fonction sur un appareil public ou partagé.",
        CODE_INFO1:
          "Votre code peut prendre quelques minutes avant d'être reçu.",
        CODE_INFO2: "Si vous n'avez pas reçu votre code, veuillez :",
        CODE_INFO3:
          "Vérifier que votre téléphone est ouvert, que la réceptloion est bonne selon l’endroit où vous êtes et que vous pouvez recevoir des messages.",
        CODE_INFO4: "Vous avez vérifié que vos coordonnées sont exactes.",
        NO_CODE: "Vous n'avez pas reçu le code ?",
        RESEND_CODE: "Renvoyer le code",
        UPDATE_PWD_TITLE: "Mettre à jour le mot de passe",
        UPDATE_PWD_DESC:
          "Veuillez créer votre propre mot de passe unique pour ouvrir une session dans l’application.",
        NEW_PWD: "nouveau mot de passe",
        COMFIRM_PWD: "Confirmer le nouveau mot de passe",
        PWD_NOTE:
          "Le mot de passe doit être composé de 8 à 20 caractères et d’au moins une majuscule, une minuscule, un chiffre et un caractère spécial: !@#$%^&*()-_",
        FORGOT_PWD_DESC:
          "Merci d’avoir confirmé votre compte, veuillez entrer votre nouveau mot de passe pour mettre à jour vos données d’ouverture de session.",
      },
      REGISTRATION: {
        HEADER: "Inscription",
        COMPANY_RADIO:
          "J’aimerais inscrire mon entreprise pour accéder à ce site",
        USER_RADIO:
          "Mon entreprise a déjà accès à ce site, mais pas moi. J’aimerais accéder à ce site.",
        CANCEL: "Annuler",
        SUBMIT: "SOUMETTRE",
        AFFILIATIONS: "Affiliations du fabricant",
        AUTH_MANUFACTURER: "Fabricant autorisé",
        EQUIPMENT_DESC: "Description de l'équipement",
        RESET: "RÉINITIALISER",
        REGISTER: "Inscrire",
        COMPANY_REG_FORM: "Formulaire d’inscription d’une entreprise",
        USER_REG_FORM: "Formulaire d’inscription d’un utilisateur",
        COMPANY_FORM_INFO:
          "Veuillez remplir le formulaire suivant pour demander l’accès au site Web de LeasingSource et/ou pour obtenir l’autorisation d’utiliser nos services financiers.",
        USER_FORM_INFO_1:
          "Lorsque votre entreprise s’est inscrite à ce site, nous avons attribué les droits d’administration à une personne de votre entreprise qui lui permettent d’accorder l’accès au site à des utilisateurs supplémentaires. Si vous connaissez votre administrateur de site désigné, veuillez le contacter ou contacter le responsable de votre compte.",
        USER_FORM_INFO_2:
          "Si cela est plus pratique, veuillez remplir le formulaire ci-dessous et nous communiquerons avec le responsable de votre compte afin que vous obteniez l’autorisation d’accéder au site.",
        CONTACT_US: "Communiquez avec nous",
        INDICATES: "Indique",
        REQUIRED: "requis",
        FIELD: "champ",
        COMPANY_INFO: "Renseignements sur l’entreprise",
        COMPANY_NAME: "Nom de l’entreprise",
        DBA_TRADE_NAME: "Raison sociale ou appellation commerciale",
        HQ: "Siège social",
        BRANCH: "Bifurquer",
        STREET_ADDRESS: "Adresse",
        PO_BOX: "(Cases postales non autorisées)",
        CITY: "Ville",
        STATE: "État",
        PLEASE_SELECT: "Veuillez sélectionner",
        ZIP: "Zip",
        PHONE: "Téléphone",
        "1_800s": "(Numéros 1-800 non autorisés)",
        FAX: "Télécopieur",
        BUSINES_TYPE: "Type d’entreprise",
        PROPRIETORSHIP: "Entreprise individuelle",
        PARTNERSHIP: "Société de personnes",
        CORPORATION: "Grande entreprise",
        LESS_THAN_TWO: "Êtes-vous en affaires depuis moins de 2 ans?",
        YES: "Oui",
        NO: "Non",
        COMPANY_CONTACT_INFO: "Coordonnées de la partie principale",
        USER_CONTACT_INFO: "Coordonnées",
        FIRST_NAME: "Prénom",
        LAST_NAME: "Nom",
        TITLE: "Titre ",
        EMAIL: "Courriel",
        CONFIRM_EMAIL: "Confirmer Courriel",
        SUCCESS_1:
          "Nous vous remercions de votre demande. Le responsable de votre compte ou le Soutien aux concessionnaires vous contactera avec vos données d'accès.",
        SUCCESS_2:
          "Si vous avez des questions au sujet de votre accès au site, veuillez contacter le responsable de votre compte au {{phoneNo}} option 1.",
      },
      USER_PASSWORD_ERROR:
        "Vous avez entré un nom d’utilisateur et un mot de passe invalides. Veuillez essayer de nouveau.",
      USERNAME_EMAIL_ERROR:
        "Vous avez entré un nom d’utilisateur ou un courriel invalide. Veuillez essayer de nouveau.",
      LOGIN_ATTEMPTS_ERROR:
        "Vous avez dépassé la limite de tentatives de connexion. Vous ne pouvez pas accéder à votre compte pour le moment.",
      FAILED_LOGIN_ERROR:
        "Échec de la tentative d’ouverture de session. Essayez de nouveau.",
      GENERIC_LOGIN_ERROR:
        "Une erreur inconnue s’est produite. Veuillez fermer votre navigateur et contacter votre administrateur de système pour obtenir de l’aide.",
    },
    PORTAL_ERROR: {
      NOT_FOUND_ERR: "Page introuvable!",
      ERR_MESG_INVALID_REQUIRED:
        "Le champ obligatoire doit contenir une valeur",
      ERR_MESG_INVALID_PASSWORD:
        "Mot de passe insuffisant. Le mot de passe doit être composé de 6 à 20 caractères et d’au moins une majuscule, de minuscules, de chiffres et de caractères spéciaux: !@#$%^&*()-_",
      ERR_MESG_INVALID_PASSWORD_FORGOT:
        "Mot de passe insuffisant. Le mot de passe doit être composé de 6 à 20 caractères et d’au moins une majuscule, de minuscules, de chiffres et de caractères spéciaux: !@#$%^&*()-_",
      ERR_MESG_INVALID_NEW_PASSWORD:
        "Mot de passe insuffisant. Veuillez respecter les exigences ci-dessous.",
      ERR_MESG_INVALID_CONFIRM_NEW_PASSWORD:
        "Le nouveau mot de passe et la confirmation du mot de passe ne concordent pas",
      ERR_MESG_INVALID_ZIP_CODE:
        "Le code postal Zip aux États-Unis doit être composé de 5 chiffres (p. ex. 94043).",
      ERR_MESG_INVALID_NUMERIC: "Veuillez entrer une valeur numérique.",
      ERR_MESG_INVALID_PHONE:
        "Veuillez entrer un numéro de téléphone valide (p. ex. 203-123-1234)",
      ERR_MESG_INVALID_EMAIL: "Veuillez entrer une adresse courriel valide.",
      ERR_MESG_EMAIL_MISMATCH: "Le courriel ne correspond pas!",
      ERR_MESG_INVALID_FAX:
        "Veuillez entrer un numéro de télécopieur valide (p. ex. 203-123-1234)",
      ERR_MESG_INVALID_PERSONAL_NAME: "Veuillez entrer un nom valide.",
      ERR_MESG_INVALID_TITLE: "Veuillez entrer un titre valide.",
      ERR_MESG_INVALID_USERNAME: "Veuillez entrer un nom d’utilisateur valide.",
      ERR_MESG_DOC_MANDATORY:
        "Veuillez ouvrir et lire le document avant de sélectionner «Accepter»",
      ERR_MESG_SERVER_TIMEOUT:
        "Une erreur s’est produite en contactant le serveur. Veuillez réessayer plus tard.",
      ERR_MESG_REQUIRED_OPTION: "Veuillez sélectionner une option.",
      ERR_MESG_OTP_OPTION: "Tentative échouée. Essayez de nouveau.",
      ERR_MESG_REQUIRED_CODE: "Veuillez entrer un code valide.",
      ERR_MESG_INVALID_OTP_CODE: "Code entré invalide. Essayez de nouveau.",
      ERR_MESG_INVALID_UPDATE_PASSWORD:
        "Impossible de mette à jour le mot de passe. Le nouveau mot de passe ne respecte pas les exigences de longueur, de complexité ou d’historique du site.",
      ERR_MESG_INVALID_OLD_PASSWORD:
        "Mot de passe invalide. Veuillez entrer un nouveau mot de passe que vous n’avez jamais utilisé auparavant.",
    },
  },
};
